<template>
  <VContainer
    fluid
    class="pa-6"
  >
    <DialogWrapper
      v-model="deleteDialog"
      width="396"
      content-class="tt-card"
      hide-overlay
      @click:outside="deleteDialog = false"
    >
      <DeleteForm
        @delete="handleDelete"
        @cancel="deleteDialog = false"
      >
        <h3 class="delete-form__title">
          Удалить задачу?
        </h3>
        <p class="mb-3">
          Задача будет удалена,
          при этом все ранее назначенные наставникам задачи по этому шаблону будут сохранены.
        </p>
        <p class="mb-0">
          Для новых сотрудников эта задача назначаться не будет.
        </p>
      </DeleteForm>
    </DialogWrapper>

    <VRow>
      <VCol cols="1" />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <VRow>
              <VCol>
                <h1
                  class="tt-text-headline-1 mb-2"
                  data-test="title-form"
                >
                  Чек-лист задач для наставников
                </h1>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <h2>Чек-лист</h2>
              </VCol>
              <VCol
                v-if="!entityList.length"
                cols="12"
                class="pt-0"
              >
                У вас пока нет ни одного пункта чек-листа.
              </VCol>
              <template v-else>
                <VCol
                  v-for="check in entityList"
                  :key="check.id"
                  cols="12"
                  class="pt-0"
                >
                  <TutorChecklistCellForm
                    v-if="openedModals[check.id]"
                    edit
                    :edit-entity="check"
                    :interval-list="intervalList"
                    :location-id="locationId"
                    @close="handleCellClose($event)"
                    @save="handleCellSave($event)"
                  />
                  <div
                    v-else
                    class="d-flex justify-space-between align-center"
                  >
                    <div>
                      <div>{{ check.name }}</div>
                      <div class="tt-black--text text--lighten-2">
                        {{ intervalToString(check.interval) }}
                      </div>
                    </div>
                    <div class="d-flex flex-nowrap">
                      <TTBtn
                        fab
                        depressed
                        small
                        color="transparent tt-ghost--text"
                        :ripple="false"
                        :data-test="`button-edit-${check.id}`"
                        @click="openModal(check)"
                      >
                        <VIcon size="19">
                          fal fa-pencil
                        </VIcon>
                      </TTBtn>
                      <TTBtn
                        fab
                        depressed
                        color="transparent tt-ghost--text"
                        :ripple="false"
                        small
                        :data-test="`button-delete-${check.id}`"
                        @click.stop="openDelete(check)"
                      >
                        <VIcon size="19">
                          fal fa-trash-alt
                        </VIcon>
                      </TTBtn>
                    </div>
                  </div>
                  <VDivider class="mt-5" />
                </VCol>
              </template>
            </VRow>
            <VRow>
              <VCol
                cols="12"
                class="pt-0"
              >
                <div>Добавление нового пункта чек-листа</div>
              </VCol>
              <VCol>
                <TutorChecklistCellForm
                  :interval-list="intervalList"
                  :location-id="locationId"
                  @close="handleCellClose($event)"
                  @save="handleCellSave($event)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <VDivider />
              </VCol>
            </VRow>
            <VRow>
              <VCol class="mt-4">
                <TTBtn
                  large
                  color="tt-secondary"
                  class="mr-6"
                  depressed
                  data-test="button-close"
                  @click="handleClose"
                >
                  Закрыть
                </TTBtn>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              color="white"
              elevation="1"
              large
              class="mb-2"
              data-test="modal-button-close"
              @click="handleClose"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import Vue from 'vue';
import * as snamiApi from '@/services/backend/snami';
import { dictionaryKeys } from '@/constants';

import DeleteForm from '@/components/forms/DeleteForm.vue';
import TutorChecklistCellForm from '@/components/forms/TutorChecklistCellForm.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';

export default {
  name: 'TutorChecklistForm',
  components: {
    DialogWrapper,
    DeleteForm,
    TutorChecklistCellForm,
  },
  props: {
    locationId: {
      type: Number,
      required: true,
    },

  },
  data() {
    return {
      openedModals: {},
      deleteDialog: false,
      toDeleteId: 0,
      intervalMap: {},
      entityList: [],
      loading: false,
      intervalList: [],
    };
  },
  async created() {
    try {
      const { data: { data: intervalList } } = await this.$repositories.customer
        .getCustomerDictionary({ data: { key: [dictionaryKeys.checkpointTemplate] } });
      intervalList.checkpoint_template_interval.forEach((item) => {
        this.intervalMap[item.value] = item.label;
      });
      this.intervalList = intervalList.checkpoint_template_interval;
      this.intervalList.push({
        value: -1,
        label: 'Пользовательский интервал',
      });
    } catch (e) {
      console.warn(e);
    }

    this.getData();
  },
  methods: {
    intervalToString(interval) {
      if (!interval) return this.intervalMap['1d'];
      if (this.intervalMap[interval]) return this.intervalMap[interval];
      const days = parseInt(interval.split('d')[0], 10);
      return `${days}-й день`;
    },

    handleClose() {
      this.$emit('close');
    },
    handleCellSave(item) {
      this.getData();
      if (item.id) {
        this.handleCellClose(item);
      }
    },
    handleCellClose(item) {
      Vue.set(this.openedModals, item.id, false);
    },
    openModal(item) {
      Vue.set(this.openedModals, item.id, true);
    },
    handleDelete() {
      snamiApi.deleteTutorCheckpointTemplate(this.toDeleteId).then(() => {
        this.deleteDialog = false;
        this.toDeleteId = 0;
        this.getData();
      }).catch((e) => console.warn(e));
    },
    openDelete(item) {
      this.deleteDialog = true;
      this.toDeleteId = item.id;
    },

    getData() {
      this.loading = true;
      snamiApi.getTutorCheckpointTemplateList({ filter: { location_id: this.locationId } }).then((r) => {
        const { data } = r.data;
        this.entityList = data;
      })
        .catch((e) => console.warn(e))
        .finally(() => { this.loading = false; });
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
