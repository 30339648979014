<template>
  <VMenu
    content-class="v-menu-shadow"
    open-on-hover
    bottom
    offset-y
    max-width="240"
    min-width="240"
  >
    <template #activator="{ on, attrs }">
      <div
        class="d-flex flex-column align-start cursor--pointer checklist-cell"
        v-bind="attrs"
        v-on="on"
      >
        <VIcon
          v-if="status.icon"
          :color="status.color"
          size="12"
        >
          {{ status.icon }}
        </VIcon>
        <div
          v-else
          class="checklist-cell__dot"
          :style="`background-color: ${status.color}`"
        />
        <div
          class="checklist-cell__line mt-1"
          :style="`background-color: ${status.color}`"
        />
      </div>
    </template>
    <VCard>
      <VCardText class="pa-3">
        <VRow no-gutters>
          <VCol cols="12">
            <h4 class="tt-text-caption tt-light-mono-46--text mb-1">
              Наставник: {{ tutorName }}
            </h4>
          </VCol>
          <VCol cols="12">
            <h4 class="tt-text-body-2">
              {{ item.templateName }}
            </h4>
          </VCol>
        </VRow>
        <VDivider class="my-2" />
        <VRow>
          <VCol
            cols="6"
          >
            <VRow no-gutters>
              <VCol
                class="py-0"
                cols="12"
              >
                <h4 class="tt-text-caption tt-light-mono-100--text">
                  {{ checkDate }}
                </h4>
              </VCol>
              <VCol
                class="py-0"
                cols="12"
              >
                <h4 class="tt-text-caption tt-light-mono-46--text">
                  {{ item.templateInterval }}
                </h4>
              </VCol>
            </VRow>
          </VCol>
          <VCol
            cols="6"
          >
            <VRow no-gutters>
              <VCol
                class="py-0"
                cols="12"
              >
                <h4 class="tt-text-caption tt-light-mono-100--text">
                  {{ realDate }}
                  <VIcon
                    v-if="status.icon"
                    :color="status.color"
                    size="12"
                  >
                    {{ status.icon }}
                  </VIcon>
                </h4>
              </VCol>
              <VCol
                class="py-0"
                cols="12"
              >
                <h4 class="tt-text-caption tt-light-mono-46--text">
                  {{ status.text }}
                </h4>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
        <div
          class="d-flex align-center mt-2"
        >
          <NewSAvatar
            data-test="tutor-checkpoint-cell-avatar"
            :img="staff.photo_url"

            :name="`${staff.last_name} ${staff.first_name}`"
            class="mr-3"
          />
          <span class="tt-text-body-2">
            {{ staff.last_name }} {{ staff.first_name }}
          </span>
        </div>
      </VCardText>
    </VCard>
  </VMenu>
</template>

<script>
import NewSAvatar from '@/components/ui/NewSAvatar.vue';
import { formatDate } from '@/utils';

const checklistStatuses = {
  PENDING: 'PENDING',
  WITHOUT_PROBLEM: 'WITHOUT_PROBLEM',
  FAILED: 'FAILED',
};

export default {
  name: 'TutorCheckpointsCell',
  components: { NewSAvatar },
  props: {
    item: {
      type: Object,
      required: true,
    },
    extraStaffMap: {
      type: Object,
      default: () => ({}),
    },
    staff: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tutorName() {
      const tutor = this.extraStaffMap[this.staff.tutor_id];
      return tutor ? `${tutor.last_name} ${tutor.first_name}` : '';
    },
    checkDate() {
      return formatDate(this.item.meet_date);
    },
    realDate() {
      return this.item.state === checklistStatuses.PENDING ? 'Нет данных' : formatDate(this.item.update_date);
    },
    status() {
      switch (this.item.state) {
        case checklistStatuses.FAILED:
          return {
            color: '#FF3E00',
            icon: 'fas fa-times-circle',
            text: 'Не выполнено',
          };
        case checklistStatuses.WITHOUT_PROBLEM:
          return {
            color: '#00D358',
            icon: 'fas fa-check-circle',
            text: 'Проведено',
          };
        case checklistStatuses.PENDING:
        default:
          return {
            color: '#D8D9DA',
            text: 'Ожидается',
          };
      }
    },
  },
};
</script>

<style>
.checklist-cell .checklist-cell__line {
  width: 100%;
  height: 4px;
}

.checklist-cell .checklist-cell__dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.flex-two-column > div {
  flex: 0 0 50%;
  max-width: 50%;
}

</style>
