<template>
  <VForm>
    <VRow>
      <VCol
        cols="12"
        class="pt-0"
      >
        <TTTextField
          :value="entity.name"
          large
          label="Название задачи"
          placeholder="Введите новый пункт"
          data-test="tutor-checkpoint-cell-name"
          :error="validation('name').status"
          :error-messages="validation('name').value"
          @input="v => handleChange('name',v)"
        />
      </VCol>
    </VRow>
    <VRow align="start">
      <VCol cols="6">
        <TTSelect
          :value="entity.interval"
          :items="intervalList"
          item-text="label"
          data-test="tutor-checkpoint-cell-interval"
          label="Интервал"
          large
          :messages="['Выберите из предложенных интервалов или задайте свой']"
          placeholder="Выберите интервал"
          :error="validation('interval').status"
          :error-messages="validation('interval').value"
          @change="v => handleChange('interval',v)"
        />
      </VCol>
      <VCol
        v-if="entity.interval === -1"
        cols="2"
      >
        <TTTextField
          v-model="interval"
          v-mask="'###'"
          large
          label="День встречи"
          placeholder="0"
          :error="validation('interval').status"
          :error-messages="validation('interval').value"
          @input="handleChange('',undefined, 'interval')"
        />
      </VCol>
      <VCol :cols="entity.interval === -1? 4 : 6">
        <!-- Заглушка для выравнивания кнопок -->
        <div style="margin-top: 31px" />
        <div class="d-flex align-center">
          <TTBtn
            v-if="edit"
            color="tt-secondary"
            large
            class="mr-3"
            depressed
            data-test="button-close"
            :disabled="saving"
            @click="handleClose"
          >
            Отменить
          </TTBtn>
          <TTBtn
            large
            depressed
            data-test="button-save"
            :disabled="saving"
            :loading="saving"
            @click="save"
          >
            {{ edit? 'Сохранить' : 'Добавить' }}
          </TTBtn>
        </div>
      </VCol>
    </VRow>
  </VForm>
</template>

<script>
import { mask } from 'vue-the-mask';
import { validation, handleError, watchValidationStatus } from '@/services';
import * as snamiApi from '@/services/backend/snami';

const defaultEntity = {
  interval: '',
  location_id: null,
  name: '',
};

export default {
  name: 'TutorChecklistCellForm',
  directives: { mask },
  props: {
    editEntity: {
      type: Object,
      default: () => ({}),
    },
    intervalList: {
      type: Array,
      default: () => ([]),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    locationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      validationStatus: [],
      saving: false,
      interval: '',
      entity: { ...defaultEntity },
    };
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  async created() {
    this.entity.location_id = this.locationId;
    if (this.edit) {
      this.handleEntity();
    }
  },
  methods: {
    validation,
    handleError,
    save() {
      this.saving = true;
      const data = { ...this.entity };
      if (data.interval === -1) {
        data.interval = `${this.interval || 0}d`;
      }
      snamiApi.saveTutorCheckpointTemplate({ type: this.edit ? 'update' : 'create', data })
        .then(() => {
          this.$emit('save', data);
          if (!this.edit) {
            this.entity = { ...defaultEntity, location_id: this.locationId };
            this.interval = '';
          }
        }).catch((e) => this.handleError(e)).finally(() => {
          this.saving = false;
        });
    },
    handleEntity() {
      this.entity = Object.assign(this.entity, this.editEntity);
      if (this.intervalList.findIndex((item) => item.value === this.entity.interval) === -1) {
        this.interval = this.entity.interval;
        this.entity.interval = -1;
      }
    },
    handleClose() {
      this.$emit('close', this.editEntity || {});
    },
    handleChange(name, value, target) {
      this.entity[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
  },
};
</script>

<style scoped>
  /* stylelint-ignore no-empty-source */
</style>
