<template>
  <div
    v-if="checkpoints.length || staff.checkpoints.length"
    class="d-flex align-end justify-start mr-n1 tutor-checkpoints-list"
  >
    <div
      v-for="checkpoint in checkpoints"
      :key="checkpoint.id"
      :style="`width: ${cellWidth}px`"
      class="pr-1"
    >
      <TutorCheckpointsCell
        :item="checkpoint"
        :staff="staff"
        :extra-staff-map="extraStaffMap"
      />
    </div>
  </div>
  <div v-else>
    Для данного сотрудника чеклист не создан
  </div>
</template>

<script>
import TutorCheckpointsCell from '@/components/tutor/TutorCheckpointsCell.vue';

export default {
  name: 'TutorCheckpointsList',
  components: {
    TutorCheckpointsCell,
  },
  props: {
    checkpoints: {
      type: Array,
      required: true,
    },
    staff: {
      type: Object,
      required: true,
    },
    extraStaffMap: {
      type: Object,
      default: () => ({}),
    },
    cellWidth: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
.tutor-checkpoints-list {
  height: 24px;
}
</style>
