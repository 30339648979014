<template>
  <VContainer
    fluid
    class="report-tutor"
  >
    <DialogWrapper
      v-model="dialog"
      fullscreen
      @click:outside="dialog = false"
    >
      <TutorChecklistForm
        v-if="dialog"
        :location-id="currentLocationId"
        @close="dialog = false"
      />
    </DialogWrapper>

    <VRow class="align-center">
      <VCol cols="9">
        <h1 class="mb-2 tt-text-headline-1">
          Отчет по задачам наставника
        </h1>
        <p class="tt-black--text text--lighten-2 mb-4">
          Здесь можно посмотреть результаты работы наставников.
        </p>
      </VCol>
    </VRow>
    <VRow>
      <VCol class="py-0">
        <VRow class="mb-4 align-end">
          <VCol
            v-if="!needHideLocations"
            cols="5"
            class="py-0"
          >
            <TTSelect
              ref="locationDropDown"
              :value="currentLocationId"
              :items="locations"
              item-text="name"
              item-value="id"
              return-object
              large
              label="Локация"
              :menu-props="{ auto : true }"
              class="tt-select-attach"
              attach
              placeholder="Выбор локации"
              @change="handleLocation"
            >
              <template #selection="{item,disabled}">
                <TextTemplate
                  class="v-select__selection v-select__selection--comma"
                  :disabled="disabled"
                  :text="item.name"
                  :show-prefix="!item.is_active && !!item.id"
                />
              </template>
              <template #item="{item}">
                <TextTemplate
                  :text="item.name"
                  :show-prefix="!item.is_active && !!item.id"
                />
              </template>
            </TTSelect>
          </VCol>
          <VCol
            :cols="needHideLocations? 3 : 6"
            class="py-0"
          >
            <TTTextField
              v-model="filter.search"
              placeholder="Поиск по ФИО"
              append-icon="fal fa-search"
              large
              label="Поиск сотрудника"
              data-test="input-search"
              @input="handleStaffSearch"
            />
          </VCol>
          <VCol class="py-0" cols="auto">
            <VMenu
              content-class="v-menu-shadow"
              offset-y
              :nudge-bottom="8"
              bottom
              max-width="460"
              :close-on-content-click="false"
              left
            >
              <template #activator="{ on }">
                <div class="mt-5">
                  <VBadge
                    :value="selectedFilters"
                    dark
                    color="black"
                    :content="selectedFilters"
                    overlap
                  >
                    <TTBtn
                      fab
                      depressed
                      large
                      color="tt-secondary"
                      :ripple="false"
                      class="table-menu-button"
                      data-test="staff-button-show-menu"
                      v-on="on"
                    >
                      <VIcon
                        size="19"
                        color="black"
                      >
                        fal fa-sliders-h
                      </VIcon>
                    </TTBtn>
                  </VBadge>
                </div>
              </template>
              <VCard class="v-menu-card">
                <VCardText class="pa-6">
                  <VRow>
                    <VCol
                      class="pt-3 pb-3"
                      cols="6"
                    >
                      <p class="tt-text-body-2 mb-0">
                        Поиск по наставнику
                      </p>
                    </VCol>
                    <VCol
                      v-if="filter.tutor_search.length"
                      cols="6"
                      class="pt-3 text-right"
                    >
                      <span
                        class="tt-dark-blue--text"
                        @click="resetTutorSearch"
                      >
                        Сбросить
                      </span>
                    </VCol>
                    <VCol
                      class="pt-0 pb-2"
                      cols="12"
                    >
                      <TTTextField
                        v-model="filter.tutor_search"
                        placeholder="Поиск по ФИО"
                        append-icon="fal fa-search"
                        large
                        hide-details
                        single-line
                        data-test="side-staff-report-tutor-search"
                        @input="handleTutorSearch"
                      />
                    </VCol>
                    <VCol
                      class="pt-0 pb-6"
                      cols="12"
                    >
                      <p class="tt-light-mono-46--text mb-0">
                        Выберите наставника, чтобы увидеть, как он работает с подопечными
                      </p>
                    </VCol>
                    <VCol
                      class="pt-0 pb-3"
                      cols="6"
                    >
                      <p class="tt-text-body-2 mb-0">
                        Период выхода на работу
                      </p>
                    </VCol>
                    <VCol
                      v-if="filter.start_at_from.length"
                      cols="6"
                      class="pt-0 text-right"
                    >
                      <span
                        class="tt-dark-blue--text"
                        @click="handlePeriodChange([])"
                      >
                        Сбросить
                      </span>
                    </VCol>
                    <VCol
                      cols="12"
                      class="pt-0 pb-3"
                    >
                      <SRangePicker
                        append-icon="fal fa-calendar-alt"
                        placeholder="Выберите период"
                        :value="searchPeriod"
                        :max-date="dateNow"
                        single-line
                        hide-details="auto"
                        data-test="search-period"
                        @input="handlePeriodChange"
                      />
                    </VCol>
                  </VRow>
                </VCardText>
              </VCard>
            </VMenu>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow v-if="currentLocationId">
      <VCol v-if="dataLoaded && !staff.length">
        <h2 class="mb-3">
          Нет данных
        </h2>
        <p class="mb-0">
          Нет данных для отображения по заданным параметрам.
        </p>
      </VCol>
      <VCol v-else>
        <TTDataTable
          :items="staff"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          :loading="!dataLoaded"
        >
          <template #header.report="{ header }">
            <div
              ref="tutorListCell"
              v-resize="onResize"
              class="w-100 text-center report-tutor__header"
            >
              {{ header.text }}
              <template v-if="showHeaderArrows">
                <div
                  v-if="startRow"
                  class="report-tutor__header-left black--text text--link d-flex"
                  @click="handleLeft"
                >
                  <VIcon
                    x-small
                    color="#0B1218"
                    class="mr-2"
                    :ripple="false"
                  >
                    fal fa-chevron-left
                  </VIcon>
                  <span>К более ранним</span>
                </div>
                <div
                  v-if="startRow + TOTAL_CHECKPOINTS_SHOW < maxTemplateLength"
                  class="report-tutor__header-right black--text text--link d-flex"
                  @click="handleRight"
                >
                  <span>К более поздним</span>
                  <VIcon
                    small
                    color="#0B1218"
                    :ripple="false"
                    class="ml-2"
                  >
                    fal fa-chevron-right
                  </VIcon>
                </div>
              </template>
            </div>
          </template>
          <template #item="{item}">
            <tr>
              <td
                class="cursor--pointer"
                @click="$router.push(`/staff/${item.id}`)"
              >
                <div class="d-flex align-center">
                  <NewSAvatar
                    class="mr-3"
                    data-test="report-tutor-avatar"
                    :img="item.photo_url"
                    :name="`${item.last_name} ${item.first_name}`"
                  />
                  <div>
                    <p class="mb-0">
                      {{ item.last_name }} {{ item.first_name }}
                    </p>
                    <p
                      v-if="extraStaffMap[item.tutor_id]"
                      class="tt-black--text text--lighten-2 mb-0"
                    >
                      Наставник:
                      {{ extraStaffMap[item.tutor_id].last_name }} {{ extraStaffMap[item.tutor_id].first_name }}
                    </p>
                  </div>
                </div>
              </td>
              <td style="vertical-align: middle!important">
                <TutorCheckpointsList
                  :checkpoints="prepareCheckpoints(item.checkpoints)"
                  :staff="item"
                  :extra-staff-map="extraStaffMap"
                  :cell-width="cellWidth"
                />
              </td>
            </tr>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
    <VRow
      v-else
    >
      <VCol cols="12">
        <h2 class="mb-3">
          Локация не выбрана
        </h2>
        <p class="mb-0">
          Выберите локацию для получения данных.
        </p>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import debounce from 'lodash/debounce';
import { pageLocationToken } from '@/services';
import * as snamiApi from '@/services/backend/snami';
import { dictionaryKeys } from '@/constants';

import NewSAvatar from '@/components/ui/NewSAvatar.vue';
import SRangePicker from '@/components/ui/SRangePicker.vue';
import TutorChecklistForm from '@/components/forms/TutorChecklistForm.vue';
import TutorCheckpointsList from '@/components/tutor/TutorCheckpointsList.vue';
import localStorageInterface from '@/services/localStorage/_utils';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import TextTemplate from '@/components/shared/TextTemplate.vue';

const pageReportTutorSearchToken = localStorageInterface('snami_page_report_tutor_search');
const pageReportTutorSearchTutorToken = localStorageInterface('snami_page_report_tutor_search_tutor');
const pageReportTutorSearchPeriodToken = localStorageInterface('snami_page_report_tutor_search_period');

const TOTAL_CHECKPOINTS_SHOW = 10;

export default {
  name: 'ReportTutor',
  components: {
    TextTemplate,
    DialogWrapper,
    NewSAvatar,
    SRangePicker,
    TutorChecklistForm,
    TutorCheckpointsList,
  },
  data() {
    return {
      filter: {
        search: pageReportTutorSearchToken.get() || '',
        start_at_from: '',
        start_at_till: '',
        tutor_search: pageReportTutorSearchTutorToken.get() || '',
      },
      dateNow: this.$dayjs().format('YYYY-MM-DD'),
      searchPeriod: pageReportTutorSearchPeriodToken.get() || [],
      listWidth: 0,
      intervalMap: {},
      maxTemplateLength: 0,
      activeEntity: {},
      meetingFlatMap: {},
      extraStaffMap: {},
      staff: [],
      dataLoaded: false,
      startRow: 0,
      TOTAL_CHECKPOINTS_SHOW,
      locations: [],
      currentLocationId: null,
      dialog: false,
    };
  },
  computed: {
    selectedFilters() {
      let totalFilters = 0;
      if (this.filter.tutor_search) totalFilters += 1;
      if (this.filter.start_at_from) totalFilters += 1;
      return totalFilters;
    },
    drawer() {
      return this.$store.getters.drawer;
    },
    totalCheckpointsShow() {
      return TOTAL_CHECKPOINTS_SHOW + (this.drawer ? 0 : 5);
    },
    cellWidth() {
      const listLength = Math.min(this.maxTemplateLength, TOTAL_CHECKPOINTS_SHOW);
      return Math.ceil(this.listWidth / listLength);
    },
    showHeaderArrows() {
      return this.maxTemplateLength > TOTAL_CHECKPOINTS_SHOW;
    },
    headers() {
      let text;
      if (this.showHeaderArrows) {
        text = `${this.startRow + 1} – ${this.startRow + TOTAL_CHECKPOINTS_SHOW} задачи`;
      } else {
        text = `1 - ${this.maxTemplateLength} задачи`;
      }
      const headers = [
        {
          sortable: false,
          text: 'Сотрудники',
          align: 'left',
          value: 'staff',
          width: 300,
        },
        {
          sortable: false,
          text,
          align: 'center',
          value: 'report',
        },
      ];
      return headers;
    },
    currentLocationName() {
      if (this.currentLocationId && this.locations.length) {
        const locationIdx = this.locations.findIndex((item) => item.id === this.currentLocationId);
        if (locationIdx !== -1) {
          return this.locations[locationIdx].name;
        }
      }
      return '';
    },
    needHideLocations() {
      return this.$di.ff.get('reports_tutor_hide_locations');
    },
  },
  async created() {
    this.debouncedGetData = debounce(this.handleFilter, 500);
    const loc = pageLocationToken.get();
    if (loc) {
      this.currentLocationId = loc;
    }
    try {
      const { data: { data: intervalList } } = await this.$repositories.customer
        .getCustomerDictionary({ data: { key: [dictionaryKeys.checkpointTemplate] } });
      intervalList.checkpoint_template_interval.forEach((item) => {
        this.intervalMap[item.value] = item.label;
      });

      const { data: { data: locations } } = await this.$repositories.location.list({});
      this.locations = locations;

      this.getData();
    } catch (e) {
      console.warn(e);
    }
  },
  mounted() {
    if (!this.currentLocationId) {
      this.$refs.locationDropDown.$children[0].isMenuActive = true;
    }
    this.$root.$on('editTutorChecklist', this.openDialog);
  },
  beforeDestroy() {
    this.$root.$off('editTutorChecklist', this.openDialog);
  },

  methods: {
    prepareCheckpoints(checkpoints) {
      if (!this.showHeaderArrows) {
        return checkpoints;
      }
      return checkpoints.slice(this.startRow, this.startRow + TOTAL_CHECKPOINTS_SHOW);
    },
    openDialog() {
      this.dialog = true;
    },
    resetTutorSearch() {
      this.filter.tutor_search = '';
      pageReportTutorSearchTutorToken.set(this.filter.tutor_search);
      this.debouncedGetData();
    },
    handlePeriodChange(period) {
      this.searchPeriod = period;
      pageReportTutorSearchPeriodToken.set(this.searchPeriod);
      this.handleFilter();
    },
    handleLocation(item) {
      pageLocationToken.set(item.id);
      this.currentLocationId = item.id;
      this.handleFilter();
    },
    handleStaffSearch() {
      pageReportTutorSearchToken.set(this.filter.search);
      this.debouncedGetData();
    },
    handleTutorSearch() {
      pageReportTutorSearchTutorToken.set(this.filter.tutor_search);
      this.handleFilter();
    },
    handleFilter() {
      this.getData();
    },
    handleLeft() {
      this.startRow = (this.startRow - TOTAL_CHECKPOINTS_SHOW < 0)
        ? 0 : this.startRow - TOTAL_CHECKPOINTS_SHOW;
    },
    handleRight() {
      this.startRow = (this.startRow + TOTAL_CHECKPOINTS_SHOW > this.maxTemplateLength - TOTAL_CHECKPOINTS_SHOW)
        ? this.maxTemplateLength - TOTAL_CHECKPOINTS_SHOW : this.startRow + TOTAL_CHECKPOINTS_SHOW;
    },
    handleData(data) {
      if (data) {
        data.extra_staffs.forEach((item) => {
          this.extraStaffMap[item.id] = item;
        });

        const templateMap = {};
        data.templates.forEach((item) => {
          templateMap[item.id] = item;
        });

        this.staff = data.staffs.map((item) => ({
          ...item,
          checkpoints: [],
        }));

        const staffMap = {};
        this.staff.forEach((staff) => {
          staffMap[staff.id] = staff;
        });

        data.checkpoints.forEach((item) => {
          const checkpoint = {
            ...item,
            templateName: templateMap[item.template_id].name,
            templateInterval: templateMap[item.template_id].interval,
          };
          staffMap[item.staff_id].checkpoints.push(checkpoint);
        });

        let maxTemplateLength = 0;
        this.staff.forEach((staff, i) => {
          if (staff.checkpoints.length > maxTemplateLength) {
            maxTemplateLength = this.staff[i].checkpoints.length;
          }
        });
        this.maxTemplateLength = maxTemplateLength;
      }
    },
    async getData() {
      if (!this.currentLocationId) return;
      this.filter.start_at_from = this.searchPeriod[0] || '';
      this.filter.start_at_till = this.searchPeriod[1] || '';
      this.startRow = 0;
      this.dataLoaded = false;
      this.staff = [];
      this.extraStaffMap = {};
      try {
        const { filter } = this;
        const { data: { data } } = await snamiApi.getTutorCheckpointList({
          filter: {
            location_id: this.currentLocationId,
            ...filter,
          },
        });
        this.handleData(data);
      } catch (e) {
        console.warn(e);
      }
      this.dataLoaded = true;
      this.onResize();
    },
    onResize() {
      this.listWidth = 0;
      this.$nextTick(() => {
        this.calculateListWidth();
      });
    },
    calculateListWidth() {
      if (this.$refs.tutorListCell) {
        this.listWidth = this.$refs.tutorListCell.clientWidth;
      }
    },
  },
};
</script>

<style>
.report-tutor .report-tutor__header {
  position: relative;
}
.report-tutor .report-tutor__header .v-icon.v-icon::after {
  display: none;
}
.report-tutor .report-tutor__header-left {
  left: 0;
  position: absolute;
  top: 2px;
}
.report-tutor .report-tutor__header-right {
  right: 0;
  position: absolute;
  top: 2px;
}

</style>
